import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import { Link, useNavigate } from 'react-router-dom';
import './SignUpSelection.css';
import continueIcon from '../assets/continue-icon.svg';
import renderVideo from '../assets/Render.mp4'; // Adjust the path to your video file
import { db } from '../firebase'; 

const SignUpSelection = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850); // Check for mobile devices

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850); // Update state on resize
    };

    // Check if window is defined (to avoid errors in non-browser environments)
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    // Check if the app is embedded in an iframe
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid); // Ensure user.uid is valid
        if (user.uid) { // Check if user.uid is not null
          try {
            const snapshot = await getDoc(userRef); // Use getDoc to fetch the document
            const userData = snapshot.data(); // Get the data from the snapshot

            if (userData) {
              if (userData.signedUp) {
                if (userData.userType === 'creator') {
                  navigate(`/creator/${user.uid}`);
                } else if (userData.userType === 'studio') {
                  navigate(`/studio/${user.uid}`);
                }
              } else {
                if (userData.userType === 'creator') {
                  navigate('/creator-typeform', { state: { userId: user.uid } });
                } else if (userData.userType === 'studio') {
                  navigate('/studio-typeform', { state: { userId: user.uid } });
                }
              }
            } else {
              console.error("User data not found for UID:", user.uid);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        } else {
          console.error("User ID is null or undefined.");
        }
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  useEffect(() => {
    if (!isMobile) { // Only set up video handling if not on mobile
      const videoElement = document.getElementById('loading-video');
      const loadingOverlay = document.querySelector('.loading-overlay');

      const handleVideoEnd = () => {
        if (loadingOverlay) {
          loadingOverlay.classList.add('hidden'); // Add hidden class to fade out
          setTimeout(() => {
            setLoading(false); // Set loading to false after fade out
          }, 300); // Match the duration of the CSS transition
        }
      };

      if (videoElement) {
        videoElement.addEventListener('ended', handleVideoEnd);
      }

      return () => {
        if (videoElement) {
          videoElement.removeEventListener('ended', handleVideoEnd);
        }
      };
    } else {
      setLoading(false); // Skip loading state on mobile
    }
  }, [isMobile]);


  const handleButtonClick = (option) => {
    // Toggle selection
    if (selectedOption === option) {
      setSelectedOption(null); // Deselect if already selected
    } else {
      setSelectedOption(option); // Select the new option
    }
  };

  const handleContinue = () => {
    if (selectedOption === 'creator') {
      navigate('/signup-creator'); // Navigate to Creator Profile
    } else if (selectedOption === 'studio') {
      navigate('/signup-studio'); // Navigate to Studio Profile
    }
  };

  return (
    <div className='signup-selection'>
      {loading ? (
        !isMobile && (
          <div className="loading-overlay">
            <video
              id="loading-video"
              src={renderVideo}
              autoPlay
              muted
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 9999,
              }}
            />
          </div>
        )
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh'}}>
          <h1 className='fade-in'>Choose Your Path</h1>
          <p className='fade-in'>
            Is there a difference? <Link to="https://newgame.me/#faq">Check the FAQ</Link>
          </p>
          <div className={`button-container ${selectedOption === 'studio' ? 'studio-selected' : ''} ${selectedOption === 'creator' ? 'creator-selected' : ''}`}>
            <button
              onClick={() => handleButtonClick('creator')} 
              className={`creator-button fade-button ${selectedOption === 'creator' ? 'selected' : ''}`}
            >
              CREATOR
            </button>
            <button
              onClick={() => handleButtonClick('studio')} 
              className={`studio-button fade-button ${selectedOption === 'studio' ? 'selected' : ''}`}
            >
              STUDIO
            </button>
          </div>
          <button 
            className={`continue-path-button fade-button`} 
            onClick={handleContinue} 
            disabled={!selectedOption}
          >
            <div className="text-wrapper">CONTINUE</div>
            <img src={continueIcon} alt="Continue" className="icon" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SignUpSelection;
