import React, { useState, useEffect } from 'react';
import {db} from '../firebase'; // Import your Firebase auth instance
import { updateEmail, updatePassword, deleteUser, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './SettingsPage.css'; // Import the CSS for styling
import { useNavigate } from 'react-router-dom'; // Import Link and useNavigate for navigation
import { auth } from '../firebase'; // Import your Firebase auth instance

const SettingsPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null); // State to hold user data
    const navigate = useNavigate(); // Initialize useNavigate
    const [isResetEmail, setIsResetEmail] = useState(false); // State to manage email reset mode
    const [isResetPassword, setIsResetPassword] = useState(false); // State to manage password reset mode
    const [signInProvider, setSignInProvider] = useState(''); // New state for sign-in provider
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user); // Set the user state when authenticated
                setEmail(user.email); // Set the email state from the user object
                
                // Determine the sign-in provider
                const providerData = user.providerData[0]; // Get the first provider
                // Update sign-in provider based on the provider ID
                if (providerData.providerId === 'google.com') {
                    setSignInProvider('Google'); // Set to "Google"
                } else if (providerData.providerId === 'twitter.com') {
                    setSignInProvider('X/ Twitter'); // Set to "X/ Twitter"
                } else if (providerData.providerId === 'password') {
                    setSignInProvider('Email and Password'); // Set to "Email and Password"
                } else {
                    setSignInProvider(providerData.providerId); // Default to provider ID
                }
            } else {
                setUser(null); // Clear user state when not authenticated
                setEmail(''); // Clear email state when not authenticated
                setSignInProvider(''); // Clear sign-in provider when not authenticated
            }
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, []); // Empty dependency array to run once on mount

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                const userRef = doc(db, 'users', user.uid); // Reference to the user's document
                const snapshot = await getDoc(userRef); // Fetch the document
                if (snapshot.exists()) {
                    const data = snapshot.data(); // Get the data from the snapshot
                    setUserData(data); // Set user data from Firestore
                    console.log('User data fetched:', data); // Log the fetched user data
                } else {
                    console.error('No user data found for userId:', user.uid);
                }
            }
        };

        fetchUserData(); // Call the fetchUserData function to get user data
    }, [user]); // Dependency on user


    const handleEmailUpdate = async () => {
        if (user && user.emailVerified) { // Check if the email is verified
            try {
                await updateEmail(user, email);
                console.log('Email updated successfully to:', email);
            } catch (error) {
                console.error('Error updating email:', error);
            }
        } else {
            alert('Please verify your email before updating.');
        }
    };

    const handlePasswordUpdate = async () => {
        if (user && user.emailVerified) { // Check if the email is verified
            try {
                await updatePassword(user, password);
                console.log('Password updated successfully.');
            } catch (error) {
                console.error('Error updating password:', error);
            }
        } else {
            alert('Please verify your email before updating.');
        }
    };

    const handleAccountDeletion = async () => {
        if (user) {
            try {
                await deleteUser(user);
                // Handle post-deletion logic (e.g., redirect to login)
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    };

    const handleEditProfileNavigation = () => {
        if (user) {
            const userId = user.uid; // Get the user ID
            if (userData.userType === 'creator') {
                navigate(`/edit-creator-page/${userId}`);
            } else if (userData.userType === 'studio') {
                navigate(`/edit-studio-page/${userId}`);
            }
        }
    };

    return (
        <div className="settings-page">
            <h1>Settings</h1>
            <p>Want to edit your profile? head to the <span onClick={handleEditProfileNavigation} style={{ cursor: 'pointer', color: 'rgb(230, 8, 198)' }}>Edit Profile Page</span>.</p>
            <div className="settings-page-container">
            <p>Signed in using <strong>{signInProvider}</strong></p>
                <div className="settings-page-input-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', marginTop: '40px' }}>
                    {isResetEmail ? (
                        <>
                            <label>Updating Email</label>
                            <input type="email" value={email || ''} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" style={{ color: '#3B006F' }}/>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <button className="update-data-button" onClick={handleEmailUpdate}>Update Email</button>
                                <button className="update-data-button" onClick={() => setIsResetEmail(false)}>Cancel</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <label>Email: {email || 'No email set'}</label>
                            <button className="update-data-button" onClick={() => setIsResetEmail(true)}>Reset Email</button>
                        </>
                    )}
                </div>
                <div className="settings-page-input-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', marginTop: '40px' }}>
                {isResetPassword ? (
                        <>
                            <label>Updating Password</label>
                            <input type="password" value={password || ''} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" style={{ color: '#3B006F' }}/>
                            <div style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                            <button className="update-data-button" onClick={handlePasswordUpdate}>Update Password</button>
                            <button className="update-data-button" onClick={() => setIsResetPassword(false)}>Cancel</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <label>Password: {password ? '••••••••' : 'No password set'}</label>
                            <button className="update-data-button" onClick={() => setIsResetPassword(true)}>Reset Password</button>
                        </>
                    )}
                </div>
                <button className="delete-account-button" onClick={handleAccountDeletion}>Delete Account</button>
            </div>
        </div>
    );
};

export default SettingsPage; 