import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'none',
        display: false,
      },
      title: {
        display: false,
        text: 'Sales Data',
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function(value) {
            // Format the value to show as '80k', '90k', etc.
            return value >= 1000 ? (value / 1000).toFixed(0) + 'k' : value;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '90%', height: '100%', margin: '0 auto' }}>
      <Bar options={options} data={data} style={{ width: '100%'}}/>
    </div>



  );
};

export default BarChart; 