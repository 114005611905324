import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { auth, db } from './firebase'; // Import from firebase.js
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import HeaderApp from './components/HeaderApp'; // Import the HeaderApp component
import SignUpSelection from './components/SignUpSelection';
import CreatorProfile from './components/CreatorProfile';
import StudioProfile from './components/StudioProfile';
import Header from './components/Header'; // Import the standard header
import CreatorTypeform from './components/CreatorTypeform';
import StudioTypeform from './components/StudioTypeform'; // Import the new component
import Login from './components/Login';
import CreatorPage from './components/CreatorPage'; // Import the new component
import StudioPage from './components/StudioPage'; // Import your StudioPage component
import HeaderMobile from './components/HeaderMobile'; // Import the mobile Header component
import Footer from './components/Footer'; // Import the Footer component
import { onAuthStateChanged } from "firebase/auth";
import PasswordResetForm from './components/PasswordReset';
import PasswordSet from './components/PasswordSet'; 
import EditCreatorPage from './components/EditCreatorPage';
import PublicProfile from './components/PublicProfile';
import ChatPage from './components/ChatPage'; // Import the ChatPage component
import EditStudioPage from './components/EditStudioPage';
import GamePage from './components/GamePage'; // Import the GamePage component
import { setPersistence, browserLocalPersistence } from "firebase/auth";
import CreatorDashboard from './components/page-previews/CreatorDashboard';
import SettingsPage from './components/SettingsPage';
import PublicStudioPage from './components/publicstudiopage';	
import EditGameDataPage from './components/EditGameDataPage';
import UserProfile from './components/UserProfile'; // Import the new component

const App = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [userId, setUserId] = useState(null); // Extract userId from URL parameters
	const [userProfilePic, setUserProfilePic] = useState(null); // State for user's profile picture
	const [username, setUsername] = useState(null); // State for user's display name
	const [name, setName] = useState(null); // State for user's name
	const [userData, setUserData] = useState(null); // State for user data
	const [isNavOpen, setNavOpen] = useState(false); // State for side nav visibility
	const [isLeftNavOpen, setLeftNavOpen] = useState(false); // State for left nav visibility
	const isTypeformRoute = location.pathname === '/creator-typeform' || location.pathname === '/studio-typeform';
	const isCreatorPage = location.pathname.startsWith('/creator/');
	const isStudioPage = location.pathname.startsWith('/studio/');
	const isEditCreatorPage = location.pathname.startsWith('/edit-creator-page/');
	const isChatPage = location.pathname.startsWith('/chat-page/'); // Check if the pathname is the chat page
	const isSigningUp = location.pathname === '/signup-creator' || location.pathname === '/signup-studio' || location.pathname === '/login'; // Check if the pathname is the signup selection page
	const isGamePage = location.pathname.startsWith('/game-page/');
	const isCreatorDashboard = location.pathname === '/creator-dashboard';
	const isPublicStudioPage = location.pathname.split('/').length === 2 && /^[a-zA-Z0-9_-]+$/.test(location.pathname.split('/')[1]);
	const isPublicProfile = location.pathname.split('/').length === 2 && /^[a-zA-Z0-9_-]+$/.test(location.pathname.split('/')[1]);
	const isSettingsPage = location.pathname.startsWith('/settings/');
	const isEditGameDataPage = location.pathname.startsWith('/edit-game-data/');
	const isHeaderAppRoute = 
		(isCreatorPage || isStudioPage || isEditCreatorPage || isChatPage || isGamePage || isCreatorDashboard || isSettingsPage || isEditGameDataPage) && 
		!isSigningUp && 
		!isTypeformRoute; // Exclude Login, CreatorProfile, and StudioProfile
	const isFeaturePage = isCreatorDashboard || isSettingsPage;
	const isMobile = window.innerWidth < 850; // Check if the screen width is less than 810px
	// const [isLocked, setIsLocked] = useState(false); // Remove this line
	const hasNavigatedToCreatorPage = useRef(false); // Ref to track navigation
	const hasLoggedUserData = useRef(false); // Ref to track user data fetched logging
	const hasLoggedNavigation = useRef(false); // Ref to track navigation logging
	const hasLoggedFooterVisibility = useRef(false); // Ref to track footer visibility logging

	useEffect(() => {
		const handleScrollToTop = () => {
			if (window.innerWidth < 850) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}
		};

		handleScrollToTop(); // Call the function on location change

	}, [location.pathname]);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
				if (user) { // Check if the user is logged in
					const uid = user.uid; // Get the user ID from the user object
					setUserId(uid); // Set userId in state

					if (window.self === window.top) {
						if (uid) {
							const userRef = doc(db, 'users', uid); // Use uid here
							const snapshot = await getDoc(userRef); // Use getDoc to fetch the document
							const userData = snapshot.data(); // Get the data from the snapshot

							if (!hasLoggedUserData.current) {
								console.log('User data fetched:', userData);
								hasLoggedUserData.current = true; // Set to true after logging
							} 

							if (userData) {
								setUserProfilePic(userData.profilePic);
								setUsername(userData.username);
								setName(userData.name);
								setUserData(userData);
								// Navigation logic based on user data
								if (!isHeaderAppRoute && !hasNavigatedToCreatorPage.current && !isPublicProfile && !isPublicStudioPage) {
									if (userData.signedUp) {
										if (userData.userType === 'creator') {
											if (!hasLoggedNavigation.current) {
												console.log('Navigating to creator page:', user.uid);
												navigate(`/creator/${user.uid}`);
												hasNavigatedToCreatorPage.current = true;
												hasLoggedNavigation.current = true; // Set to true after logging
											}
										} else if (userData.userType === 'studio') {
											if (!hasLoggedNavigation.current) {
												console.log('Navigating to studio page:', user.uid);
												navigate(`/studio/${user.uid}`);
												hasNavigatedToCreatorPage.current = true; // Set to true after navigation
												hasLoggedNavigation.current = true; // Set to true after logging
											}
										}
									}
								}
							}
						} else {
							console.error('User ID is null or undefined');
						}
					}
				} else {
					console.log('User is not logged in');
					// Handle user not logged in case
				}
		});

		return () => unsubscribe(); // Clean up the listener on unmount
	}, [navigate, isHeaderAppRoute, isPublicProfile, userData, userId, isPublicStudioPage]);

	// Ensure the following useEffect is properly defined
	useEffect(() => {
		// Close the side nav when the route changes
		setNavOpen(false);
		setLeftNavOpen(false);
	}, [location.pathname]); // Listen for changes in the pathname

	// Remove the handleUnlock function
	// const handleUnlock = () => {
	// 	setIsLocked(false); // Unlock the app
	// };

	const showFooter = !isTypeformRoute && !isEditCreatorPage && !isPublicProfile && !isPublicStudioPage;

	// Footer visibility logging
	if (!hasLoggedFooterVisibility.current) {
		console.log('Footer visibility:', {
			isTypeformRoute,
			isEditCreatorPage,
			isPublicProfile,
			isPublicStudioPage,
			isSigningUp,
			isEditGameDataPage,
			shouldShowFooter: showFooter
		});
		hasLoggedFooterVisibility.current = true; // Set to true after logging
	}

	// Set persistence to local
	setPersistence(auth, browserLocalPersistence)
		.then(() => {
			// Existing sign-in logic here
		})
		.catch((error) => {
			console.error("Error setting persistence:", error); 
		});

	useEffect(() => {
		// Close the left nav when the route changes, except for the creator-dashboard
		if (isFeaturePage) {
			setLeftNavOpen(true);
		}
	}, [isFeaturePage]);

	return ( 
		<div className={`app ${isHeaderAppRoute ? 'header-app-route' : ''}`}>
			{/* {isLocked && !isPublicProfile && <LockScreen onUnlock={handleUnlock} />} */}
			{isHeaderAppRoute && (
				<HeaderApp 
					profilePic={userProfilePic} // Pass the profile picture here
					studioProfilePic={userData ? userData.studioProfilePic : null} // Ensure this is passed correctly
					username={username} 
					name={name} // Pass the name prop here
					isNavOpen={isNavOpen} 
					setNavOpen={setNavOpen} 
					isLeftNavOpen={isLeftNavOpen}
					setLeftNavOpen={setLeftNavOpen}
					userId={userId} 
					userData={userData} // Pass userData if needed
				/>
			)}
			{!isTypeformRoute && !isCreatorPage && !isStudioPage && !isEditCreatorPage && !isPublicProfile && !isGamePage && !isPublicStudioPage && !isEditGameDataPage && (isMobile ? <HeaderMobile /> : <Header />)}
			<div className={`main-content ${isNavOpen ? 'blur' : ''} ${isLeftNavOpen && !isFeaturePage ? 'blur' : ''}`} style={{ marginLeft: (isLeftNavOpen && isFeaturePage) ? '225px' : '0' }}>
				<Routes>
					<Route path="/" element={<SignUpSelection />} />
					<Route path="/signup-creator" element={<CreatorProfile />} />
					<Route path="/signup-studio" element={<StudioProfile />} />
					<Route path="/creator-typeform" element={<CreatorTypeform />} />
					<Route path="/studio-typeform" element={<StudioTypeform />} />
					<Route path="/login" element={<Login />} />
					<Route path="/studio/:userId" element={<StudioPage />} />
					<Route path="/creator/:userId" element={<CreatorPage />} />
					<Route path="/password-reset" element={<PasswordResetForm />} />
					<Route path="/password-set" element={<PasswordSet />} />
					<Route path="/edit-creator-page/:userId" element={<EditCreatorPage />} />
					<Route path="/:username" element={<UserProfile />} />
					<Route path="/chat-page/:userId" element={<ChatPage />} />
					<Route path="/edit-studio-page/:userId" element={<EditStudioPage />} />
					<Route path="/game-page/:userId/:gameNumber/:gameTitle" element={<GamePage />} />
					<Route path="/creator-dashboard" element={<CreatorDashboard isLeftNavOpen={isLeftNavOpen} />} />
					<Route path="/settings/:userId" element={<SettingsPage />} />
					<Route path="/:username" element={<PublicStudioPage />} />
					<Route path="/:username" element={<PublicProfile />} />
					<Route path="/edit-game-data/:userId/:gameNumber" element={<EditGameDataPage />} />
				</Routes>
			</div>
			{showFooter && <Footer />} 
		</div>
	); 
};

export default App;
