import './CreatorDashboard.css';
import actionIcon from '../../assets/function-icons/action-icon.svg';
import beaconIcon from '../../assets/function-icons/beaconIcon.png';
import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import mojoMeleeIcon from '../../assets/function-icons/mojoMeleeIcon.png';
import cosmikBattleIcon from '../../assets/function-icons/cosmikBattleIcon.png';
import React from 'react';
import BarChart from '../BarChart';
import PieChart from '../PieChart';

const CreatorDashboard = ({ isLeftNavOpen }) => {
    // Prepare the data for the bar chart
    const chartData = {
        labels: ['8:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00'],
        datasets: [
            {
                label: 'Content Impressions',


                data: [40000, 59000, 80000, 81000, 56000, 55000, 40000],
                backgroundColor: 'rgb(230, 8, 198)',
                borderRadius: 5,
                barThickness: 15,
                maxBarThickness: 15,

                minBarLength: 2,

            },
            {
                label: 'Content Engagement',
                data: [8000, 20000, 16000, 18000, 12000, 10000, 9000],
                backgroundColor: 'rgb(89, 10, 160)',
                borderRadius: 5,
                barThickness: 15,
                maxBarThickness: 15,
                minBarLength: 2,
            }
        ],
    };

    // Prepare data for the impressions pie chart
    const impressionsData = {
        labels: ['X', 'TikTok', 'YouTube'],
        datasets: [
            {
                label: 'Clicks',

                data: [5320, 2000, 1000], // Example data
                backgroundColor: ['rgba(171, 0, 199)', 'rgba(89, 10, 160)', 'rgba(223, 223, 223)'],
                borderRadius: 8,


            },
            {
                data: 100,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',

            }

        ],
    };

    // Prepare data for the clicks pie chart
    const clicksData = {
        labels: ['X', 'TikTok', 'YouTube'],
        datasets: [
            {
                label: 'Clicks',

                data: [300000, 700000, 400000], // Example data
                backgroundColor: ['rgba(171, 0, 199)', 'rgba(89, 10, 160)', 'rgba(223, 223, 223)'],
                borderRadius: 8,



            },
            {
                data: 100,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',

            }

        ],
    };

    return (
        <div className={`dashboard-container header-app-route no-header-blur ${isLeftNavOpen ? 'resized' : 'not-resized'}`}>
            <header className="dashboard-header">
                <div className="dashboard-actions">
                    <button>
                        <img src={actionIcon} alt="Campaign Offers" />
                        <div className="action-label">
                            <h2>Campaign Offers</h2>
                            <p>Review Offers</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Manage Campaigns" />
                        <div className="action-label">
                            <h2>Manage Campaigns</h2>
                            <p>Manage current campaigns</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Analytics Report" />
                        <div className="action-label">
                            <h2>Analytics Report</h2>
                            <p>Review your metrics</p>
                        </div>
                    </button>
                    <button>
                        <img src={actionIcon} alt="Reputation & Ratings" />
                        <div className="action-label">
                            <h2>Reputation & Ratings</h2>
                            <p>Check your reputation</p>
                        </div>
                    </button>
                </div>
            </header>
            <div className="campaigns-container" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
            <section className="current-campaigns">
                <div className="current-campaigns-header" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div className = "current-header-left" style={{ display: "flex", alignItems: "start"}}>
                        <h2>Current Campaigns</h2>
                    </div> 
                    <div className = "current-header-right" style={{ display: "flex", alignItems: "end"}}>
                        <h3>Full Offer Value</h3>
                    </div>
                </div> 
                    <div className="campaign-list">
                    <div className="campaign-item">
                        <span className="CampaignGame" style={{ paddingRight: "5px" }}> <img src={cosmikBattleIcon} alt="Game Icon" className="game-icon"/> Cosmik Battle </span>
                        <span className="CampaignTitle" style={{ marginRight: "auto" }}> - Vulcan Campaign</span>
                        <span className="CampaignValue">$3,250</span>
                    </div>
                    <div className="campaign-item">
                        <span className="CampaignGame" style={{ paddingRight: "5px" }}> <img src={beaconIcon} alt="Game Icon" className="game-icon"/> The Beacon </span>
                        <span className="CampaignTitle" style={{ marginRight: "auto" }}> - Season 1 Campaign</span>
                        <span className="CampaignValue">$1,500</span>
                    </div>
                    <div className="campaign-item">
                        <span className="CampaignGame" style={{ paddingRight: "5px" }}> <img src={mojoMeleeIcon} alt="Game Icon" className="game-icon"/> Mojo Melee </span>
                        <span className="CampaignTitle" style={{ marginRight: "auto" }}> - Tundra Dome Campaign</span>
                        <span className="CampaignValue">$12,100</span>
                    </div>
                </div>
            </section>
            <section className="new-offers current-campaigns">
            <div className="current-campaigns-header" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div className = "current-header-left" style={{ display: "flex", alignItems: "start"}}>
                        <h2>New Campaign Offers</h2>
                    </div> 
                    <div className = "current-header-right" style={{ display: "flex", alignItems: "end"}}>
                        <h3>Full Offer Value</h3>
                    </div>
                </div>
                <div className="offer-item" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between" }}>
                    <div className="offer-campaign-container" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <div className="offer-campaign-icon">
                        <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={dreamrunnerIcon} alt="Game Icon" className="game-icon"/>
                    </div>
                    <div className="offer-campaign" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between", marginRight: "auto", paddingLeft: "10px" }}>
                        <span>Dreamrunner</span>
                        <h3 style={{ margin: "0px", color: "rgb(32, 32, 32, .7)" }}>The Soul Of Myra Campaign</h3>
                    </div>
                    <div className="offer-value">
                        <span>$14,200</span>
                    </div>
                    </div>
                    <div className="offer-description" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start" }}>
                        <h3 style={{ textAlign: "left" }}>Dreamrunner campaign strategy will engage players, create hype around Knights of the Ether.</h3>
                        <h3 style={{ textAlign: "left", textDecoration: "underline", color: "#3B006F" }}>Full Campaign Overview</h3>
                    </div>
                </div>
            
            </section>
            </div>
            <section className="content-frequency frequency-text">
                <h2>Content Frequency | </h2>
                <h3 style={{ textDecoration: "underline", color: "#3B006F", textUnderlineOffset: "4px" }}>Today</h3>
                <h3>Weekly</h3>
                <h3>Monthly</h3>
                <h3>Yearly</h3>
                <div className="frequency-chart">


                    {/* Placeholder for chart */}
                </div>


            </section>
            <section className="content-frequency" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
                <div className="current-campaigns">
                    <BarChart data={chartData} />
                </div>


                <div className="current-campaigns quick-numbers-container" style={{ display: "flex", flexDirection: "column", alignItems: "start",justifyContent: "space-between"}}>





                    <div className="quick-numbers" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>



                        <div className="locked-earnings quick-numbers-item current-campaigns">
                            <h2>$14,500</h2>


                            <h3>Locked Earnings</h3>
                        </div>


                        <div className="total-earnings quick-numbers-item current-campaigns">
                            <h2>$77,000</h2>
                            <h3>Total Earnings</h3>
                        </div>




                        <div className="live-campaigns quick-numbers-item current-campaigns">
                          <h2>6</h2>
                          <h3>Live Campaigns</h3>
                        </div>




                    </div>

                    <div className="numbers-pie-chart" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
                        <div className="numbers-pie-chart-item current-campaigns" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: "10px" }}>
                            <h3>Click-Thrus</h3>
                            <h2>8,320</h2>
                            <PieChart data={impressionsData}/>


                        </div>
                        <div className="numbers-pie-chart-item current-campaigns" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: "10px" }}>
                        <h3>Impressions</h3>
                            <h2>1.4M</h2>
                            <PieChart data={clicksData}/>

                        </div>
                    </div>

                </div>
            </section>
            <div style={{ height: "30px", width: "100%" }}>

</div>
        </div> 
    );  
};

export default CreatorDashboard;
