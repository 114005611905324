import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import PublicStudioPage from './publicstudiopage';
import PublicProfile from './PublicProfile';

const UserProfile = () => {
    const { username } = useParams();
    const [userId, setUserId] = useState(null);
    const [userType, setUserType] = useState(null);
    const [loading, setLoading] = useState(true);
    const db = getFirestore();

    useEffect(() => {
        const fetchUserData = async () => {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('username', '==', username));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userDoc = querySnapshot.docs[0];
                const userData = userDoc.data();
                setUserId(userDoc.id);
                setUserType(userData.userType);
            } else {
                console.error('No user found with username:', username);
            }
            setLoading(false);
        };

        fetchUserData();
    }, [username, db]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (userType === 'studio') {
        return <PublicStudioPage userId={userId} />;
    } else if (userType === 'creator') {
        return <PublicProfile userId={userId} />;
    } else {
        return <div>User type not recognized.</div>;
    }
};

export default UserProfile;