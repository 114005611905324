import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable aspect ratio maintenance
    plugins: {
      legend: {
        position: 'left',
        align: 'end',
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
        }
      },

      title: {
        display: false,
        text: 'Pie Chart Example',
      },
    },
  };

  return (
    <div style={{ width: '90%', height: '300px', margin: '0 auto' }}> {/* Set width to 90% and a fixed height */}
      <Pie options={options} data={data} style={{ width: '100%', height: '100%', marginTop: "-50px", marginLeft: "-20px" }} />
    </div>
  );
};

export default PieChart; 