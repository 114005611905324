import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"; // Import getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAh7GQRd7U2yKm-DjloyoxRU0sbuLQRTXU",
  authDomain: "new-game-sign-up.firebaseapp.com",
  projectId: "new-game-sign-up",
  storageBucket: "new-game-sign-up.appspot.com",
  messagingSenderId: "797376101381",
  appId: "1:797376101381:web:ae3ec7377f3407beb5706c",
  measurementId: "G-ER0N9E1SQW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig); // Always initialize with config
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Firebase Storage


const analytics = getAnalytics(app); // Initialize Firebase Analytics
const auth = getAuth(app); // Initialize Firebase Auth
// Test Firebase Initialization 
console.log("Firebase initialized successfully:", app);

export { app, analytics, auth, db, storage}; // Export trackEvent
