import React from 'react';
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore"; // Import Firestore functions
import { useNavigate, useParams } from 'react-router-dom';
import './gamepage.css';
import Carousel from './Carousel';
import Adventureicon from '../assets/genres/adventure-dark.svg';
import Battleroyalicon from '../assets/genres/battleroyale-dark.svg';
import Fightingicon from '../assets/genres/fighting-dark.svg';
import FPSicon from '../assets/genres/fps-dark.svg';
import Minigamesicon from '../assets/genres/minigames-dark.svg';
import Racingicon from '../assets/genres/racing-dark.svg';
import RPGicon from '../assets/genres/rpg-dark.svg';
import Strategyicon from '../assets/genres/strategy-dark.svg';
import Survivalicon from '../assets/genres/survival-dark.svg';
import iosIcon from '../assets/OSystems/ios-dark.svg';
import macosIcon from '../assets/OSystems/macos-dark.svg';
import gplayIcon from '../assets/OSystems/gplay-dark.svg';
import windowsIcon from '../assets/OSystems/windows-dark.svg';
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import TelegramIcon from '../assets/socials/telegram-dark.svg';
import DiscordIcon from '../assets/socials/discord-dark.svg';
import WebsiteIcon from '../assets/socials/web-icon.svg';
import MessageShareDark from '../assets/share/message-share-dark.svg';
import XShareDark from '../assets/share/x-share-dark.svg';
import LinkShareDark from '../assets/share/Link-share-dark.svg';
import LinkShareLight from '../assets/share/Link-share.svg'; // Import light version
import MessageShareLight from '../assets/share/message-share.svg'; // Import light version
import XShareLight from '../assets/share/x-share.svg'; // Import light version

const genreIcons = {
    Adventure: Adventureicon,
    'Battle Royale': Battleroyalicon,
    Fighting: Fightingicon,
    FPS: FPSicon,
    'Minigames': Minigamesicon,
    Racing: Racingicon,
    RPG: RPGicon,
    Strategy: Strategyicon,
    Survival: Survivalicon,
};

const platformIcons = {
    iOS: iosIcon,
    Mac: macosIcon,
    Android: gplayIcon,
    Windows: windowsIcon,
};

const getHandleFromUrl = (url, platform) => {
    const urlObj = new URL(url);
    switch (platform) {
      case 'instagram':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'youtube':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'x':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'tiktok':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'twitch':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'facebook':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'telegram':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      case 'discord':
        return urlObj.pathname.split('/')[1]; // Get the handle from the path
      default:
        return '';
    }
};

const GamePage = () => {
    const { userId, gameNumber } = useParams();
    const [userData, setUserData] = React.useState(null);
    const [gameData, setGameData] = React.useState(null); // State for game data
    const [loading, setLoading] = React.useState(true);
    const [gameTitle, setGameTitle] = React.useState('');
    const db = getFirestore();
    const navigate = useNavigate();
    const [isShareSelected, setIsShareSelected] = React.useState(false); // State to manage share button selection
    const [isHovered, setIsHovered] = React.useState({
      link: false,
      message: false,
      insta: false,
      x: false,
    });
    const [copyMessageVisible, setCopyMessageVisible] = React.useState(false); // State to manage visibility of copy message  
  
    const updateShareStatus = async () => {
      const userRef = doc(db, 'users', userId); // Reference to the user's document
      try {
        await updateDoc(userRef, { shared: true }); // Update the shared property to true
        console.log('User share status updated successfully');
      } catch (error) {
        console.error('Error updating share status:', error);
      }
    };

    React.useEffect(() => {
        const fetchUserData = async () => {
            const userRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                setUserData(userDoc.data());
                setGameTitle(userDoc.data().games[gameNumber][`${gameNumber}title`]);
                setGameData(userDoc.data().games[gameNumber]);
            } else {
                console.error('No user data found for userId:', userId);
            }
        };

        fetchUserData();
        setLoading(false);
    }, [userId, db, gameNumber, gameData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <p>User data not found.</p>;
    }


    return (
        <div className="creator-page header-app-route game-page">
            <div className="profile-banner">
                <img 
                    src={gameData[`${gameNumber}profileBanner`] || 'defaultBanner.png'} 
                    alt="Profile Banner"  
                    className="banner-image" 
                />
                <div className="overlay"></div>
                <div className="edit-button">
                    <button className="nav-button" onClick={() => navigate(`/edit-game-data/${userId}/${gameNumber}`, { state: { gameData } })}
                    >Edit Profile</button>
                </div>
                <div className="share-button" style={{display: 'flex', flexDirection: 'column'}}>
            <button 
              className={`nav-button ${isShareSelected ? 'share-selected' : ''}`} 
              onClick={() => setIsShareSelected(!isShareSelected)}
            >
              {isShareSelected ? (
                <div className="share-icons">
                  <img 
                    src={isHovered.link ? LinkShareLight : LinkShareDark} 
                    alt="Link Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, link: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, link: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      navigator.clipboard.writeText(`https://app.newgame.me/${gameTitle}`); // Copy the profile URL to clipboard
                      setCopyMessageVisible(true); // Show the copy message
                      setTimeout(() => setCopyMessageVisible(false), 2000); // Hide the message after 2 seconds
                    }}
                  />
                  <img 
                    src={isHovered.message ? MessageShareLight : MessageShareDark} 
                    alt="Message Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, message: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, message: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const message = `Check out my profile on New Game+: https://app.newgame.me/${gameTitle}`; // Customize your message
                      window.open(`sms:?body=${encodeURIComponent(message)}`); // Initiate SMS share
                    }}
                  />
                  <img 
                    src={isHovered.x ? XShareLight : XShareDark} 
                    alt="X Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, x: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, x: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const postUrl = `https://x.com/intent/tweet?text=Check out my profile on @newgameUS : https://app.newgame.me/${gameTitle}`;
                      window.open(postUrl, '_blank'); // Open the post in a new tab
                    }}
                  />
                </div>
              ) : (
                <p>Share</p>
              )}
            </button>
            {copyMessageVisible && <div className="copy-message">Copied to clipboard!</div>}
          </div>
                <div className="profile-info">
                    <img src={gameData[`${gameNumber}profilePic`]} alt="Profile" className="profile-pic" />
                    <h2 className="profile-displayname">{gameData[`${gameNumber}title`]}</h2>
                </div>
            </div>
            <div className="profile-details-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="question-container bio">
                    <p>{gameData[`${gameNumber}description`]}</p>
                </div>
                <div className="carousel-container">
                    <Carousel items={gameData[`${gameNumber}content`]} navigation={true} />
                </div>
                <div className="profile-details" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="columns">
                        <div className="question-container featured-content" style={{ minWidth: '100px', width: '50%' }}>
                            <div className="genre-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Game Genres</h3>
                                </div>
                                <div className="genre-icons">   
                                {gameData[`${gameNumber}genres`]?.map((genre, index) => (
                                        <img 
                                            key={index} 
                                            src={genreIcons[genre.trim()]} 
                                            alt={genre.trim()} 
                                            className="genre-icon" 
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="development-stage-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Development Stage</h3>
                                </div>
                                <div className="genre-icons">   
                                    <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>
                                        {gameData[`${gameNumber}developmentStage`]}
                                    </p>
                                </div>
                            </div>
                            <div className="development-stage-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Studio</h3>
                                </div>
                                <div className="genre-icons">   
                                    <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>
                                        <a href={`/studio/${userId}`} target="_blank" rel="noopener noreferrer">{userData?.name}</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="social-links-section-container gamepage-section" style={{ width: '50%' }}>
                        <div className="platforms-section-container gamepage-section">
                                <div className="section-title" style={{ width: '100%' }}>
                                    <h3>Compatible Platforms</h3>
                                </div>
                                <div className="platform-icons genre-icons">   
                                    {gameData[`${gameNumber}platforms`]?.map((platform, index) => (
                                        <img 
                                            key={index} 
                                            src={platformIcons[platform.trim()]} 
                                            alt={platform.trim()} 
                                            className="platform-icon genre-icon" 
                                        />
                                    ))} 
                                </div>
                            </div>
                            <div className="section-title" style={{ width: '100%' }}>
                                <h3>Social Links</h3>
                            </div>
                            <div className="social-accounts-list">
                                {gameData[`${gameNumber}socialUrls`] && 
                                    Object.entries(gameData[`${gameNumber}socialUrls`]).map(([platform, url], index) => {
                                        const trimmedPlatform = platform.trim();
                                        return (
                                            url && ( // Only render if url is populated
                                                <div key={index} className="social-account-item">
                                                    <div className="social-links">
                                                        <div className="social-link-item">
                                                            {trimmedPlatform === 'instagram' && (
                                                                <>
                                                                    <img src={InstaIcon} alt="Instagram" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'instagram')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'tiktok' && (
                                                                <>
                                                                    <img src={TiktokIcon} alt="TikTok" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'tiktok').replace('@', '')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'x' && (
                                                                <>
                                                                    <img src={XIcon} alt="X" className="social-icon" />
                                                                    <a className="handle-link" href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'x')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'youtube' && (
                                                                <>
                                                                    <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'youtube').replace('@', '')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'facebook' && (
                                                                <>
                                                                    <img src={FacebookIcon} alt="Facebook" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'facebook')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'twitch' && (
                                                                <>
                                                                    <img src={TwitchIcon} alt="Twitch" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        @{getHandleFromUrl(url, 'twitch')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'discord' && (
                                                                <>
                                                                    <img src={DiscordIcon} alt="Discord" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        {getHandleFromUrl(url, 'discord')}
                                                                    </a>
                                                                </>
                                                            )}
                                                            {trimmedPlatform === 'telegram' && (
                                                                <>
                                                                    <img src={TelegramIcon} alt="Telegram" className="social-icon" />
                                                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                                                        {new URL(url).hostname}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        );
                                    })}
                                {userData[`game${gameNumber.charAt(gameNumber.length - 1)}website`] && (
                                    <div className="social-account-item">
                                        <div className="social-links">
                                            <div className="social-link-item">
                                                <img src={WebsiteIcon} alt="Website" className="social-icon" />
                                                <a href={userData[`game${gameNumber.charAt(gameNumber.length - 1)}website`]} target="_blank" rel="noopener noreferrer">
                                                    {new URL(userData[`game${gameNumber.charAt(gameNumber.length - 1)}website`]).hostname}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GamePage;  