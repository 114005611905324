import React, { useEffect, useState } from 'react'; // Import useState and useEffect
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getFirestore, doc, onSnapshot, updateDoc } from "firebase/firestore"; // Import Firestore functions
import './CreatorPage.css'; // Import the CSS file
import './studiopage.css'; // Import the CSS file
import locationDropIcon from '../assets/images/location-drop-icon.svg';
import webIcon from '../assets/socials/web-icon.svg';
import discordIcon from '../assets/socials/discord-icon.svg';
import telegramIcon from '../assets/socials/telegram-icon.svg';
import docsIcon from '../assets/socials/docs-icon.svg';
import MessageShareDark from '../assets/share/message-share-dark.svg';
import XShareDark from '../assets/share/x-share-dark.svg';
import LinkShareDark from '../assets/share/Link-share-dark.svg';
import LinkShareLight from '../assets/share/Link-share.svg'; // Import light version
import MessageShareLight from '../assets/share/message-share.svg'; // Import light version
import XShareLight from '../assets/share/x-share.svg'; // Import light version

const regionNames = {
  NA: "North America",
  EU: "Europe",
  EAS: "East Asia",
  SA: "South America",
  AU: "Australia and Pacific Islands",
  CAS: "Central Asia",
  ME: "Middle East",
  NAF: "North Africa", 
  RU: "Russia",
  SAF: "Sub-Saharan Africa",
  SAS: "South Asia",
  SEA: "South-East Asia",
  CA: "Central America",
};

const StudioPage = ({ userId }) => {
  const { userId: paramUserId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [studioResources, setStudioResources] = useState([]); // State for studio resources
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);
  const db = getFirestore(); // Initialize Firestore
  const [isShareSelected, setIsShareSelected] = useState(false); // State to manage share button selection
  const [isHovered, setIsHovered] = useState({
    link: false,
    message: false,
    insta: false,
    x: false,
  });
  const [copyMessageVisible, setCopyMessageVisible] = useState(false); // State to manage visibility of copy message
  const [username, setUsername] = useState(''); // Define username state


  const updateShareStatus = async () => {
    const userRef = doc(db, 'users', paramUserId); // Reference to the user's document
    try {
      await updateDoc(userRef, { shared: true }); // Update the shared property to true
      console.log('User share status updated successfully');
    } catch (error) {
      console.error('Error updating share status:', error);
    }
  };

  useEffect(() => {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  useEffect(() => {
    console.log("User ID from URL:", username); // Check the userId from URL
    const userRef = doc(db, 'users', paramUserId); // Use paramUserId instead of userId
    const unsubscribe = onSnapshot(userRef, (snapshot) => {
      const data = snapshot.data();
      if (data) {
        setUserData(data);
        setUsername(data.username);
        
        // Fetch studio resources
        if (data.studioResources) {

          // Check if there are more than one link
          const links = data.studioResources.split(',').map(url => url.trim());
          const resourcesArray = links.length > 1 
            ? links.map(url => ({ url })).filter(resource => resource.url) // Split and filter if more than one link
            : links[0] ? [{ url: links[0] }] : []; // If one link, return it as an object; if none, return an empty array

          console.log('Studio links:', resourcesArray); // Log the separated links

          setStudioResources(resourcesArray);
        }
      } else {
        console.error('No data found for user:', paramUserId);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [paramUserId, db, navigate, userId, username]);

  useEffect(() => {
    const handleResize = (e) => {
      let data;
      if (data && data.context === 'iframe.resize') { // Check if data is defined and context matches
        const iframe = document.querySelector(`iframe[src="${data.src}"]`); // Check for the iframe
        if (iframe && data.height) {
          iframe.height = data.height; // Set the iframe height if available
        }
      }
    };

    window.addEventListener('message', handleResize);

    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const card = document.querySelector('.studio-page .game-card'); // Select the game card

    const handleMouseMove = (event) => {
      if (card) { // Check if the card exists
        const img = card.querySelector('img'); // Get the image inside the card
        const { clientX, clientY } = event;
        const { left, top, width, height } = img.getBoundingClientRect();

        // Calculate the center of the image
        const centerX = left + width / 2;
        const centerY = top + height / 2;

        // Calculate the translation based on mouse position
        const translateX = (clientX - centerX) / 2; // Adjust the divisor for sensitivity
        const translateY = (clientY - centerY) / 10; // Adjust the divisor for sensitivity

        // Apply the transformation
        img.style.transform = `translate(${translateX}px, ${translateY}px)`;
      }
    };

    if (card) {
      card.addEventListener('mousemove', handleMouseMove); // Add event listener if card exists
    }

    return () => {
      if (card) {
        card.removeEventListener('mousemove', handleMouseMove); // Clean up the event listener
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <h2>Building your profile...</h2>
        <div className="loading-spinner"></div>
      </div>
    ); // Show loading state while fetching data
  }

  if (!userData) {
    return <p>User data not found.</p>; // Handle case where userData is not available
  }
  
  return (
    <div className="creator-page header-app-route studio-page">
      <div className="profile-banner">
        <img src={userData.studioProfileBanner} alt="Profile Banner" className="banner-image" />
        <div className="overlay"></div>
        <div className="edit-button">
          <button className="nav-button" onClick={() => {
            console.log('Navigating to edit studio page for user ID:', paramUserId); // Log the user ID
            navigate(`/edit-studio-page/${paramUserId}`); // Navigate to the edit page
          }}>Edit Profile</button>
        </div>
        <div className="share-button" style={{display: 'flex', flexDirection: 'column'}}>
            <button 
              className={`nav-button ${isShareSelected ? 'share-selected' : ''}`} 
              onClick={() => setIsShareSelected(!isShareSelected)}
            >
              {isShareSelected ? (
                <div className="share-icons">
                  <img 
                    src={isHovered.link ? LinkShareLight : LinkShareDark} 
                    alt="Link Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, link: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, link: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      navigator.clipboard.writeText(`https://app.newgame.me/${username}`); // Copy the profile URL to clipboard
                      setCopyMessageVisible(true); // Show the copy message
                      setTimeout(() => setCopyMessageVisible(false), 2000); // Hide the message after 2 seconds
                    }}
                  />
                  <img 
                    src={isHovered.message ? MessageShareLight : MessageShareDark} 
                    alt="Message Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, message: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, message: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const message = `Check out my profile on New Game+: https://app.newgame.me/${username}`; // Customize your message
                      window.open(`sms:?body=${encodeURIComponent(message)}`); // Initiate SMS share
                    }}
                  />
                  <img 
                    src={isHovered.x ? XShareLight : XShareDark} 
                    alt="X Share" 
                    onMouseEnter={() => setIsHovered(prev => ({ ...prev, x: true }))}
                    onMouseLeave={() => setIsHovered(prev => ({ ...prev, x: false }))}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from triggering isShareSelected
                      updateShareStatus(userId); // Pass userId to the function
                      const postUrl = `https://x.com/intent/tweet?text=Check out my profile on @newgameUS : https://app.newgame.me/${username}`;
                      window.open(postUrl, '_blank'); // Open the post in a new tab
                    }}
                  />
                </div>
              ) : (
                <p>Share</p>
              )}
            </button>
            {copyMessageVisible && <div className="copy-message">{isMobile ? "Copied to clipboard!" : "Copied to clipboard!"}</div>}
          </div>

        <div className="profile-info">
          <img src={userData.studioProfilePic} alt="Profile" className="profile-pic" />
          <h2 className="profile-displayname">{userData.name}</h2>
          <div className="profile-location">
            <img src={locationDropIcon} alt="Location Icon" className="location-drop-icon" />
            <p>{regionNames[userData.location] || userData.location}</p>
          </div>
          {!isMobile ? (
              <div className={`question-container bio desktop-bio`}>
                <p>{userData.studioBio}</p>
              </div>
            ) : null}
        </div>
      </div>
      <div className="profile-details-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="question-container bio">
          <div className="profile-details">
          {isMobile ? (
              <div className={`question-container bio`}>
                <p>{userData.studioBio}</p>
              </div>
            ) : null}
            <div className="column center-column">
              <div className="question-container featured-content" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'fit-content'}}>
                <div className="section-title social-accounts-list" style={{ minWidth: '100px', width: '100%' }}>
                  <h3>Official Links</h3>
                </div>
                <div className="social-links-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '20px' }}>
                  {studioResources.map((resource, index) => {
                    let iconSrc = webIcon; // Default icon
                    if (resource.url.includes("discord")) {
                      iconSrc = discordIcon; // Discord icon
                    } else if (resource.url.includes("t.me")) {
                      iconSrc = telegramIcon; // Telegram icon
                    } else if (resource.url.includes("docs") || resource.url.includes("documentation") || resource.url.includes("wiki") || resource.url.includes("gitbook")) {
                      iconSrc = docsIcon; // Docs icon
                    }
                    return (
                      <a key={index} href={resource.url} target="_blank" rel="noopener noreferrer">
                        <img src={iconSrc} alt="Social Icon" className="social-icon studio-resource-icon"/>
                      </a>
                    );
                  })} 
                </div>
              </div>
              <div className="question-container featured-content" style={{ width: 'fit-content' }}>
                <div className="section-title featured-content" style={{ width: '100%' }}>
                  <h3>Game Titles</h3>
                </div>
                <div className="games-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {userData.games.game1 && (
                    <Link to={`/game-page/${paramUserId}/game1/${userData.games.game1.game1title.replace(/\s+/g, '-')}`} style={{ textDecoration: 'none' }}>
                      <div className="game-card" style={{ position: 'relative', margin: '10px 0' }}>
                        <img src={userData.games.game1.game1profileBanner} alt={userData.games.game1.game1title} className="game-banner" />
                        <h2>{userData.games.game1.game1title}</h2>
                      </div>
                    </Link> 
                  )}
                  {userData.games.game2 && (
                    <Link to={`/game-page/${paramUserId}/game2/${userData.games.game2.game2title.replace(/\s+/g, '-')}`} style={{ textDecoration: 'none' }}>
                      <div className="game-card" style={{ position: 'relative', margin: '10px 0' }}>
                        <img src={userData.games.game2.game2profileBanner} alt={userData.games.game2.game2title} className="game-banner" />
                        <h2>{userData.games.game2.game2title}</h2>
                      </div>
                    </Link>
                  )}
                  {userData.games.game3 && (
                    <Link to={`/game-page/${paramUserId}/game3/${userData.games.game3.game3title.replace(/\s+/g, '-')}`} style={{ textDecoration: 'none' }}>
                      <div className="game-card" style={{ position: 'relative', margin: '10px 0' }}>
                        <img src={userData.games.game3.game3profileBanner} alt={userData.games.game3.game3title} className="game-banner" />
                      <h2>{userData.games.game3.game3title}</h2>
                      </div>
                    </Link>
                  )}
                  {userData.games.game4 && (
                    <Link to={`/game-page/${paramUserId}/game4/${userData.games.game4.game4title.replace(/\s+/g, '-')}`} style={{ textDecoration: 'none' }}>
                      <div className="game-card" style={{ position: 'relative', margin: '10px 0' }}>
                      <img src={userData.games.game4.game4profileBanner} alt={userData.games.game4.game4title} className="game-banner" />
                      <h2>{userData.games.game4.game4title}</h2>
                      </div>
                    </Link>
                  )}
                  {(!userData.games.game1 && !userData.games.game2 && !userData.games.game3 && !userData.games.game4) && (
                    <p>No games available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudioPage;