import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, updateDoc, doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import ClipLoader from 'react-spinners/ClipLoader';
import './EditCreatorPage.css';
import downloadIcon from '../assets/images/Download-icon.svg'; // Path to your download icon
import profileIcon from '../assets/images/Profile-icon.svg'; // Path to your profile icon
import ContinueIcon from '../assets/images/angle-grey.svg'; // Path to your continue icon
import checkIcon from '../assets/images/Check-icon.svg'; // Import the check icon
import BioIcon from '../assets/images/bio-icon.svg'; // Import the bio icon
import GenreIcon from '../assets/images/genre-icon.svg'; // Import the genre icon
import PlatformIcon from '../assets/OSystems/gplay-light.svg';
import linkIcon from '../assets/images/link-chain-icon.svg'; // Path to your link icon
import InstaIcon from '../assets/socials/insta-dark.svg';
import TiktokIcon from '../assets/socials/tiktok-dark.svg';
import XIcon from '../assets/socials/X-dark.svg';
import YoutubeSocialIcon from '../assets/socials/youtube-dark.svg';
import FacebookIcon from '../assets/socials/facebook-dark.svg';
import TwitchIcon from '../assets/socials/twitch-dark.svg';
import TelegramIcon from '../assets/socials/telegram-dark.svg';
import DiscordIcon from '../assets/socials/discord-dark.svg';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage functions


const genres = [
    { name: 'Adventure', code: 'ADV' },
    { name: 'Battle Royale', code: 'BR' },
    { name: 'Fighting', code: 'FIGHT' },
    { name: 'FPS', code: 'FPS' },
    { name: 'Minigames', code: 'MINI' },
    { name: 'Racing', code: 'RACE' },
    { name: 'RPG', code: 'RPG' },
    { name: 'Strategy', code: 'STRAT' },
    { name: 'Survival', code: 'SURV' },
];

const platforms = [
    { name: 'WINDOWS', code: 'PC' },
    { name: 'Mac', code: 'MAC' },
    { name: 'iOS', code: 'IOS' },
    { name: 'Android', code: 'GPLAY' },
];

const EditGameDataPage = () => {
    const { userId, gameNumber } = useParams(); // Get userId and gameNumber from URL
    const location = useLocation();
    const { gameData } = location.state || {}; // Get gameData from state
    const [loading, setLoading] = useState(!gameData); // Set loading based on gameData presence
    const [tempGameDescription, setTempGameDescription] = useState(gameData[`${gameNumber}description`] || ''); // Use the passed game data
    const [tempGameTitle, setTempGameTitle] = useState(gameData[`${gameNumber}title`] || ''); // Use the passed game data
    const navigate = useNavigate();
    const db = getFirestore(); // Initialize Firestore
    const [isEditingGameTitle, setIsEditingGameTitle] = useState(false);
    const [gameTitleUpdated, setGameTitleUpdated] = useState(false); // Track if display name has been updated
    const [gameBanner, setGameBanner] = useState(null);
    const [gameProfilePic, setGameProfilePic] = useState(null);
    const [gameBannerUpdated, setGameBannerUpdated] = useState(false);
    const [gameProfilePicUpdated, setGameProfilePicUpdated] = useState(false);
    const [tempGameBanner, setTempGameBanner] = useState(null); // Temporary state for the banner
    const [tempGameProfilePic, setTempGameProfilePic] = useState(null); // Temporary state for the profile pic
    const [gameDescriptionUpdated, setGameDescriptionUpdated] = useState(false);
    const [isEditingGameDescription, setIsEditingGameDescription] = useState(false);
    const [gameGenresUpdated, setGameGenresUpdated] = useState(false);
    const [isEditingGameGenres, setIsEditingGameGenres] = useState(false);
    const [gamePlatformsUpdated, setGamePlatformsUpdated] = useState(false);
    const [isEditingGamePlatforms, setIsEditingGamePlatforms] = useState(false);
    const [tempGamePlatforms, setTempGamePlatforms] = useState(gameData[`${gameNumber}platforms`] || '');
    const [tempGameLinks, setTempGameLinks] = useState([]);
    const [gameLinksUpdated, setGameLinksUpdated] = useState(false);
    const [isEditingGameLinks, setIsEditingGameLinks] = useState(false);

    const [tempGameGenres, setTempGameGenres] = useState(gameData[`${gameNumber}genres`] || '');

    // Function to log the temp data
    const logTempData = () => {
        console.log('Temporary Game Data:', {
            tempGameDescription,
            tempGameTitle,
            tempGameBanner,
            tempGameGenres,
            tempGamePlatforms,
            tempGameProfilePic,
        });
    };


    useEffect(() => {
        if (gameData) {
            console.log('Game data received:', gameData); // Log the game data
            setTempGameTitle(gameData[`${gameNumber}title`] || ''); // Initialize temporary game title
            setLoading(false); // Set loading to false when gameData is available
            setTempGameBanner(gameData[`${gameNumber}profileBanner`] || null);
            setTempGameProfilePic(gameData[`${gameNumber}profilePic`] || null);
            setTempGameDescription(gameData[`${gameNumber}description`] || '');

            // Check if socialUrls is an object
            const socialUrls = gameData[`${gameNumber}socialUrls`];
            const existingGameLinks = typeof socialUrls === 'object' && socialUrls !== null ? socialUrls : {};

            // Set the temporary game links
            setTempGameLinks({
                youtube: existingGameLinks.youtube || '',
                instagram: existingGameLinks.instagram || '',
                tiktok: existingGameLinks.tiktok || '',
                x: existingGameLinks.x || '',
                facebook: existingGameLinks.facebook || '',
                twitch: existingGameLinks.twitch || '',
                telegram: existingGameLinks.telegram || '',
                discord: existingGameLinks.discord || '',
            });
            
            setTempGameGenres(gameData[`${gameNumber}genres`] || '');
        }
    }, [gameData, gameNumber]);

    const uploadFile = async (file, path) => {
        const storage = getStorage();
        const storageReference = ref(storage, path);
        await uploadBytes(storageReference, file);
        return await getDownloadURL(storageReference);
    };

    const handleUpdate = async () => {
        const updates = {};

        // Check if the gameBanner has been updated
        if (gameBanner) {
            const bannerURL = await uploadFile(gameBanner, `gameProfileBanners/${userId}/${gameNumber}/${gameBanner.name}`);
            updates[`${gameNumber}profileBanner`] = bannerURL;  
        }

        const hasUpdates = 
            tempGameDescription !== gameData[`${gameNumber}description`] ||
            tempGameTitle !== gameData[`${gameNumber}title`] ||
            tempGameBanner !== gameData[`${gameNumber}profileBanner`] ||
            tempGameGenres !== gameData[`${gameNumber}genres`] ||
            tempGamePlatforms !== gameData[`${gameNumber}platforms`] ||
            tempGameProfilePic !== gameData[`${gameNumber}profilePic`];

        if (!hasUpdates) {
            // No updates made, navigate back to the profile page
            navigate(`/game-page/${userId}/${gameNumber}/${gameNumber}title`);
            return;
        }

        const gameRef = doc(db, 'users', userId, 'games', gameNumber); // Use Firestore document reference
        const gameDoc = await getDoc(gameRef); // Check if the document exists

        if (!gameDoc.exists()) {
            console.error('No document found at the specified path:', gameRef.path);
            return; // Exit if the document does not exist
        }

        await updateDoc(gameRef, updates); // Update Firestore document
        navigate(`/game-page/${userId}/${gameNumber}/${gameData[`${gameNumber}title`]}`); // Redirect after update

        if (gameProfilePic) {
            const profileURL = await uploadFile(gameProfilePic, `gameProfilePics/${userId}/${gameProfilePic.name}`);
            updates[`${gameNumber}profilePic`] = profileURL;
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
                <p>Loading...</p>
            </div>
        );
    }


    const handleGameTitleClick = () => {
        setIsEditingGameTitle(!isEditingGameTitle);
    };

    const handleGameDescriptionClick = () => {
        setIsEditingGameDescription(!isEditingGameDescription);
    };

    const handleGameGenresClick = () => {
        setIsEditingGameGenres(!isEditingGameGenres);
    };

    const handleGamePlatformsClick = () => {
        setIsEditingGamePlatforms(!isEditingGamePlatforms);
    };

    const handleGameGenreSelect = (genreName) => {
        setTempGameGenres(prev => {
            if (prev.includes(genreName)) {
                return prev.filter(genre => genre !== genreName); // Remove genre if already selected
              } else {
                return [...prev, genreName]; // Add genre if not selected
              }
            });
    };

    const handleGamePlatformSelect = (platformName) => {
        setTempGamePlatforms(prev => {
            if (prev.includes(platformName)) {
                return prev.filter(platform => platform !== platformName); // Remove platform if already selected
              } else {
                return [...prev, platformName]; // Add platform if not selected
              }
            });
    };

    return (
        <div className="edit-creator-page header-app-route">
      <div className="profile-banner">
        <img src={gameData[`${gameNumber}profileBanner`] || "default-banner-url.png"} alt="Profile Banner" className="banner-image" style={{filter: 'blur(3px)'}}/>
        <div className="profile-info">
          <img src={gameData[`${gameNumber}profilePic`]} alt="Profile" className="profile-pic" style={{border: 'none'}}/>
          <h2>{gameData[`${gameNumber}title`]}</h2>
        </div>
      </div>
      <div className="edit-buttons-container" style = {{overflowX: 'hidden'}}>
      <div className="edit-buttons">
      <button onClick={handleGameTitleClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameTitleUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={profileIcon} alt="Profile-icon" />
            </div>
            Edit Game Title
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingGameTitle && (
                <input
                    type="text"
                    className="input"
                    placeholder={gameData[`${gameNumber}title`]} // Assuming userData.name holds the current display name
                    value={tempGameTitle} // Bind the input value to tempDisplayName
                    onChange={(e) => {
                        setTempGameTitle(e.target.value);
                        setGameTitleUpdated(true); // Mark as updated
                        logTempData(); // Log the temporary data
                    }}
                    style={{
                      padding: '8px 10px', // Increase padding for height
                      fontSize: '16px', // Adjust font size
                      lineHeight: '1.5', // Adjust line height
                      height: 'auto', // Allow height to adjust based on content
                      display: 'block', // Ensure it behaves like a block element
                    }}
                />
     )}
          <button onClick={handleGameDescriptionClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameDescriptionUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={BioIcon} alt="Bio-icon" />
            </div>
            Edit Game Description
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingGameDescription && (
                <textarea 
                    type="text"
                    className="input"
                    placeholder={gameData[`${gameNumber}description`]}
                    value={tempGameDescription}
                    onChange={(e) => {
                        setTempGameDescription(e.target.value);
                        setGameDescriptionUpdated(true);
                        logTempData(); // Log the temporary data
                    }}
                    onFocus={(e) => {
                        e.target.style.height = 'auto'; // Reset height
                        e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scroll height
                    }}
                    style={{
                        padding: '8px 10px',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        overflow: 'hidden', // Hide overflow
                        display: 'block',
                        height: '100px', // Set an initial height
                    }}
                />
     )}
            <button onClick={() => document.getElementById('banner-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameBannerUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={gameBannerUpdated ? checkIcon : downloadIcon} alt="Banner-icon" />
            </div>
            Edit Game Banner
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="banner-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            setGameBanner(file); // Set the selected file
            setTempGameBanner(URL.createObjectURL(file)); // Set the temporary banner for preview
            setGameBannerUpdated(true); // Set to true when a file is selected
            logTempData(); // Log the temporary data
          }}
        />

        <button onClick={() => document.getElementById('profile-pic-input').click()}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameProfilePicUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={gameProfilePicUpdated ? checkIcon : downloadIcon} alt="Profile-icon" />
            </div>
            Edit Game Logo
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        <input
          type="file"
          id="profile-pic-input"
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            setGameProfilePic(file); // Set the selected file
            setTempGameProfilePic(URL.createObjectURL(file)); // Set the temporary banner for preview
            setGameProfilePicUpdated(true); // Set to true when a file is selected
            logTempData(); // Log the temporary data
          }}
        />
        <button onClick={handleGameGenresClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameGenresUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={GenreIcon} alt="Genre-icon" />
            </div>
            Edit Game Genres
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingGameGenres && (
                <div className="language-list">
            {genres.map((genre) => (
              <div 
                style={{
                  height: '200px',
                }}
                key={genre.code}
                className={`language-item ${tempGameGenres.includes(genre.name) ? 'selected' : ''}`}
                onClick={() => {
                    handleGameGenreSelect(genre.name);
                    setGameGenresUpdated(true);
                    logTempData(); // Log the temporary data
                }}
              >
                {genre.name}
              </div>
            ))}
          </div>
     )}
        <button onClick={handleGamePlatformsClick}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gamePlatformsUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={PlatformIcon} alt="Platform-icon" />
            </div>
            Edit Compatible Platforms
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
            {isEditingGamePlatforms && (
                <div className="language-list">
            {platforms.map((platform) => (
              <div 
                style={{
                  height: '150px',
                }}
                key={platform.code}
                className={`language-item ${tempGamePlatforms.includes(platform.name) ? 'selected' : ''}`}
                onClick={() => {
                    handleGamePlatformSelect(platform.name);
                    setGamePlatformsUpdated(true);
                    logTempData(); // Log the temporary data
                }}
              >
                {platform.name }
              </div>
            ))}
          </div>         
     )}

<button onClick={() => setIsEditingGameLinks(!isEditingGameLinks)}>
            <div className="edit-buttons-icon" style={{ backgroundColor: gameLinksUpdated ? '#E608C6' : '#5E00D4' }}>
                <img src={linkIcon} alt="Link-icon" />
            </div>
            Edit Social Links
            <img className="continue-icon" src={ContinueIcon} alt="Continue-icon" />
            </button>
        {isEditingGameLinks && (
          <div className="social-links-container">
            <div className="social-link">
              <img src={YoutubeSocialIcon} alt="YouTube" className="social-icon youtube-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.youtube ? `YouTube: ${tempGameLinks.youtube}` : "Enter YouTube URL"}
                value={tempGameLinks.youtube}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, youtube: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={InstaIcon} alt="Instagram" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.instagram ? `Instagram: ${tempGameLinks.instagram}` : "Enter Instagram URL"}
                value={tempGameLinks.instagram}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, instagram: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={TiktokIcon} alt="TikTok" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.tiktok ? `TikTok: ${tempGameLinks.tiktok}` : "Enter TikTok URL"}
                value={tempGameLinks.tiktok}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, tiktok: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link"> 
              <img src={XIcon} alt="X" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.x ? `X: ${tempGameLinks.x}` : "Enter X URL"}
                value={tempGameLinks.x}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, x: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={FacebookIcon} alt="Facebook" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.facebook ? `Facebook: ${tempGameLinks.facebook}` : "Enter Facebook URL"}
                value={tempGameLinks.facebook}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, facebook: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={TwitchIcon} alt="Twitch" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.twitch ? `Twitch: ${tempGameLinks.twitch}` : "Enter Twitch URL"}
                value={tempGameLinks.twitch}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, twitch: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={TelegramIcon} alt="Telegram" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.telegram ? `Telegram: ${tempGameLinks.telegram}` : "Enter Telegram URL"}
                value={tempGameLinks.telegram}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, telegram: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
            <div className="social-link">
              <img src={DiscordIcon} alt="Discord" className="social-icon" />
              <input
                type="text"
                placeholder={tempGameLinks.discord ? `Discord: ${tempGameLinks.discord}` : "Enter Discord URL"}
                value={tempGameLinks.discord}
                onChange={(e) => {
                    setTempGameLinks(prev => ({ ...prev, discord: e.target.value }));
                    setGameLinksUpdated(true); // Mark as updated
                    logTempData(); // Log the temporary data
                }}
                className='input'
              />
            </div>
          </div>
        )}

    <div className="update-button-container">
        <button className="update-button" style={{textAlign: 'center'}} onClick={handleUpdate}>Update Game Data</button>
      </div>
        </div>
        </div>
        </div>
    );
};

export default EditGameDataPage; 